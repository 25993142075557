import React, {Component} from 'react';

class SendIcon extends Component {

    render() {
        return (
            <button
                onFocus={this.props.onFocus}
                onBlur={this.props.onBlur}
                onClick={(e) => {
                    e.preventDefault();
                    this.props.onClick(e);
                }}
                className="sc-user-input--send-icon-wrapper"
            >
                <img src="/assets/send.svg" alt=""/>
            </button>
        );
    }
}

export default SendIcon;
