import io from "socket.io-client";
import Const from "./Const";

const socket = io(Const.URL, {
    pingInterval: 1200000,
    pingTimeout: 1200000,
});

// socket.pingTimeout = 1200000;
// socket.pingInterval = 1200000;

export default socket;
