import {userApi} from '../../utils/api';

const Actions = {
    setUserData: data => ({
        type: 'USER:SET_DATA',
        payload: data,
    }),
    setIsAuth: bool => ({
        type: 'USER:SET_IS_AUTH',
        payload: bool,
    }),
    setHoliday: data => ({
        type: 'USER:SET_HOLIDAY',
        payload: data,
    }),
    fetchUserData: () => dispatch => {
        userApi
            .getMe()
            .then(({data}) => {
                let hol = localStorage.getItem("chatHoliday");
                let date = new Date().toISOString().split('T')[0]
                let newHol = true;

                if(hol){
                    if(hol === date){
                        newHol = false
                    }
                }

                dispatch(Actions.setUserData(data.user));
                if(newHol){
                    dispatch(Actions.setHoliday(data.holiday));
                }
            })
            .catch(error => {
                if (error.response) {
                    if (error.response.status === 403) {
                        Actions.setIsAuth(false);
                        localStorage.removeItem("chat_token");

                        let hol = localStorage.getItem("chatHoliday");
                        let date = new Date().toISOString().split('T')[0]
                        let newHol = true;

                        if(hol){
                            if(hol === date){
                                newHol = false
                            }
                        }

                        if(newHol) {
                            dispatch(Actions.setHoliday(error.response.data.holiday));
                        }
                    }
                } else if (error.request) {
                    console.log("error 2", error.request);
                } else {
                    console.log('error 1', error.message);
                }
            });
    },
    fetchUserLogin: postData => dispatch => {
        return userApi
            .signUp(postData)
            .then(({data}) => {
                const {token} = data;
                window.axios.defaults.headers.common['token'] = token;
                localStorage.setItem("chatStatus", "0");
                localStorage.setItem("chatToken", token);
                dispatch(Actions.fetchUserData());
                dispatch(Actions.setIsAuth(true));
                return data;
            })
            .catch(({response}) => {
                console.log(response);
            });
    },
};

export default Actions;
