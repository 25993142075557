import React from 'react';
import MessageList from './MessageList';
import UserInput from './UserInput';
import Header from './Header';
import GuestDialog from "./GuestDialog";
import {connect} from "react-redux";
import CategoryDialog from "./CategoryDialog";
import StarDialog from "./StarDialog";
import HolidayDialog from "./HolidayDialog";

const ChatWindow = (
    {
        messageList,
        isOpen,
        onClose,
        isAuth,
        holiday,
        status
    }
) => {

    let classList = [
        'sc-chat-window',
        isOpen ? 'opened' : 'closed',
        holiday ? 'sc-chat-holiday' : "",
        !isAuth ? "sc-chat-auth" : ""
    ];

    const chatIcon = localStorage.getItem("face") === "girl" ? "/assets/boy.svg" : "/assets/girl.svg";

    return (
        <div className={classList.join(' ')}>
            <Header
                onClose={onClose}
            />
            <div className={"sc-chat-wrapper"}>
                {
                    (isAuth) ?
                        <>
                            {
                                status === 1 || status === "1" || status === 2 || status === "2" ?
                                    <>
                                        <MessageList
                                            messages={messageList}
                                        />
                                        {
                                            status === 2 || status === "2" ? <StarDialog/> : <UserInput/>
                                        }
                                    </>
                                    :
                                    <CategoryDialog/>
                            }
                        </>
                        :
                        <GuestDialog/>
                }
            </div>
            <HolidayDialog/>
            <div className={"sc-chat-window-background"}>
                <img src={chatIcon} alt=""/>
            </div>
        </div>
    );
}

export default connect(({user, dialogs}) => ({isAuth: user.isAuth, holiday: user.holiday, dialogID: dialogs.dialogID, status: dialogs.status}))(ChatWindow);
