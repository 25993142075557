import React, {useEffect, useState} from 'react';
import {Formik} from 'formik';
import Yup from "../utils/YupLocale";
import store from '../redux/store';
import {dialogsActions} from '../redux/actions';
import {axios} from "../core";
import {withTranslation} from "react-i18next";

const CategoryDialog = ({t}) => {

    const [categories, setCategories] = useState([]);

    useEffect(() => {
        axios.get("/selector").then(data => {
            let category = data.data.category;
            category = category.filter(e => e.parent_id === 0)
            setCategories(category);
        })
    }, [])

    const schema = Yup.object({
        category_id: Yup.number().required().label(t("Бўлимни танланг")),
    });

    let activeLang = localStorage.getItem("i18nextLng");

    const handleSubmit = (values, setSubmitting) => {
        console.log("handleSubmit");
        console.log(values.category_id)
        store.dispatch(dialogsActions.fetchCreateDialog({
            category_id: values.category_id,
            text: ""
        })).then(({status}) => {
            console.log(status);
            if (status === 'success') {
                //props.history.push('/');
                //window.location = '/';
            } else {
                console.log("error");
            }
            setSubmitting(false);
        })
            .catch((error) => {
                console.log(error);
                setSubmitting(false);
            });
    }

    return (
        <div className="message--form">
            <Formik
                validationSchema={schema}
                initialValues={{
                    category_id: "",
                }}
                enableReinitialize={true}
                onSubmit={(values, {setSubmitting}) => handleSubmit(values, setSubmitting)}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleSubmit,
                      isSubmitting,
                  }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="message--form_group">
                            <label>{t("Бўлимни танланг")}</label>
                            <select
                                name="category_id"
                                onChange={handleChange}
                                value={values.category_id}
                            >
                                <option value="">{t("Танланг")}</option>
                                {
                                    categories.map(e => <option key={Math.random()} value={e.id}>{(activeLang === "ru" ? e.name_ru : (activeLang === 'uz' ? e.name_uz : e.name_en))}</option>)
                                }
                            </select>
                            <div className="message--form_info">
                                {errors.category_id && touched.category_id && errors.category_id}
                            </div>
                        </div>
                        <button type="submit" disabled={isSubmitting}>
                            {t("Киритиш")}
                        </button>
                    </form>
                )}
            </Formik>
        </div>
    );
};

export default withTranslation()(CategoryDialog);
