import React from 'react';
import {connect} from "react-redux";
import {userActions} from '../redux/actions';
import {withTranslation} from "react-i18next";

const HolidayDialog = ({t, holiday}) => {

    const handleSubmit = () => {
        let date = new Date().toISOString().split('T')[0]

        localStorage.setItem("chatHoliday", date);

        Array.from(document.querySelectorAll('.sc-chat-window')).forEach((el) => el.classList.remove('sc-chat-holiday'));
    }

    if(!holiday){
        return "";
    }

    let activeLang = localStorage.getItem("i18nextLng");

    return (
        <div className={"holiday-window"}>
            <div className="message--form">
                <div className="sc-message-list">
                    <h3>{t("Ma'lumot")}</h3>
                    <p>
                        {activeLang === 'ru' ? holiday.message_ru : (activeLang === 'uz' ? holiday.message_uz: holiday.message_qr)}
                    </p>
                </div>
                <button onClick={() => handleSubmit()} className="lang">{t("Davom ettirish")}</button>
            </div>
        </div>
    );
};

export default withTranslation()(connect(({user}) => ({
    holiday: user.holiday
}), {...userActions})(HolidayDialog));
