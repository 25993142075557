import {setLocale} from "yup";
import * as Yup from "yup";

setLocale({
    mixed: {
        default: 'Não é válido',
        required: '«${path}» to\'ldirish shart',
    },
    number: {
        cast: '«${message}» to\'ldirish shart',
        min: '«${path}» қиймати «${min}»дан катта бўлиши шарт',
        max: '«${path}» қиймати «${max}»дан кичик бўлиши шарт',
    },
});


export default Yup;
