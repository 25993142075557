import {dialogsApi, messagesApi} from '../../utils/api';
import socket from "../../core/socket";

const Actions = {
    setDialogs: items => ({
        type: 'DIALOGS:SET_ITEMS',
        payload: items,
    }),
    setChatStatus: status => dispatch => {
        console.log("setChatStatus", status);

        if(status === -1){
            localStorage.clear();
        }

        localStorage.setItem("chatStatus", status);
        dispatch({
            type: "CHAT:STATUS",
            payload: status
        });
    },
    fetchStar: ({vote, text}) => dispatch => {
        let dialog_id = localStorage.getItem("chatDialogId");
        dialogsApi.rating({dialog_id, vote, text}).then(({data}) => {

        });
        dispatch(Actions.setChatStatus(0));
    },
    setCurrentDialogId: id => dispatch => {
        if (id === "0") {
            dispatch(Actions.setChatStatus(2));
        } else {
            localStorage.setItem("chatDialogId", id);
            socket.emit('DIALOGS:JOIN', id);
            dispatch({
                type: 'DIALOGS:SET_CURRENT_DIALOG_ID',
                payload: id,
            });
        }
    },
    setOperatorParams: (name, avatar) => dispatch => {
        dispatch({
            type: 'DIALOGS:SET_OPERATOR',
            payload: {
                name: name,
                avatar: avatar
            },
        });
    },
    fetchDialogs: () => dispatch => {
        dialogsApi.getAll().then(({data}) => {
            dispatch(Actions.setDialogs(data.dialogs));
        });
    },
    fetchCreateDialog: ({text, category_id}) => dispatch => {
        dialogsApi.create({text, category_id}).then(({data}) => {
            dispatch(Actions.setChatStatus("1"));
            dispatch(Actions.setCurrentDialogId(data.id));
            dispatch(Actions.setOperatorParams("", ""));
        });
    },
    setMessages: items => ({
        type: "MESSAGES:SET_ITEMS",
        payload: items
    }),
    setCurrentDialog: dialog => ({
        type: "DIALOG:SET_DIALOG",
        payload: dialog
    }),
    fetchSendMessage: ({text, dialogId, attachments}) => dispatch => {
        return messagesApi.send(text, dialogId, attachments);
    },
    setIsLoading: bool => ({
        type: "MESSAGES:SET_IS_LOADING",
        payload: bool
    }),
    fetchMessages: dialogId => dispatch => {

        if (dialogId == null) {
            dialogId = localStorage.getItem("chatDialogId");
        }

        dialogId = localStorage.getItem("chatDialogId");

        dispatch(Actions.setIsLoading(true));
        messagesApi
            .getAllByDialogId(dialogId)
            .then(({data}) => {
                console.log("data", data);
                dispatch(Actions.setMessages(data.messages));
                let status = data.currentDialog.is_closed;

                console.log(status);

                if(status === 1){
                    dispatch(Actions.setChatStatus(2));
                }

                if(parseInt(data.currentDialog.vote) > 0){
                    dispatch(Actions.setChatStatus(0));
                }

            })
            .catch(() => {
                dispatch(Actions.setIsLoading(false));
            });
    }
};

export default Actions;
