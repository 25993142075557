import React, {useState} from 'react';
import ChatWindow from './ChatWindow';
import {withTranslation} from "react-i18next";

const Launcher = ({
                      messageList,
                      handleClick,
                      newMessagesCount,
                      onMessageWasSent,
                      onFilesSelected,
                      isOpen,
    t
                  }) => {

    const [setOpenClass] = useState(false);

    const handleClick2 = () => {
        if (handleClick !== undefined) {
            handleClick();
        } else {
            setOpenClass(!isOpen);
        }
    }

    const classList = [
        'sc-launcher',
        (isOpen ? 'opened' : ''),
    ];

    const chatIcon = localStorage.getItem("face") === "girl" ? "/assets/boy_face.svg" : "/assets/girl_face.svg";

    return (
        <div id="sc-launcher">
            <div className={classList.join(' ')} onClick={handleClick2}>
                <MessageCount count={newMessagesCount} isOpen={isOpen}/>
                <div className="sc-icon">
                    <img className={'sc-open-icon'} src="/assets/close.svg" alt=""/>
                    <img className={'sc-closed-icon'} src={chatIcon} alt=""/>
                </div>
                <div className="help">
                    <h3 className={"help-text"}>
                        <p className={"help-word"} data-text={t("Salom")} data-word={t("Ёрдам керакми?")}></p>
                    </h3>
                </div>
            </div>
            <ChatWindow
                messageList={messageList}
                onUserInputSubmit={onMessageWasSent}
                onFilesSelected={onFilesSelected}
                isOpen={isOpen}
                onClose={handleClick2}
            />
        </div>
    );
}

const MessageCount = ({count, isOpen}) => {
    if (count === 0 || isOpen === true) {
        return null;
    }
    return (
        <div className={'sc-new-messages-count'}>
            {count}
        </div>
    );
};

Launcher.defaultProps = {
    newMessagesCount: 0,
};

export default withTranslation()(Launcher);
