import React from 'react';
import {withTranslation} from 'react-i18next';

const Header = ({onClose}) => {
    return (
        <div className="sc-header">
            <div className="sc-header--close-button" onClick={onClose}>
                <img src="/assets/close.svg" alt=""/>
            </div>
        </div>
    );
}

export default withTranslation()(Header);
