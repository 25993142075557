import React from "react";
import ReactDOM from "react-dom";
import {BrowserRouter as Router} from "react-router-dom";
import {Provider} from "react-redux";
import './utils/i18n';

import {userActions} from "./redux/actions";

import store from "./redux/store";
import Layout from "./pages/Layout";

store.dispatch(userActions.fetchUserData());

localStorage.getItem("face") === "girl" ? localStorage.setItem("face", "boy") : localStorage.setItem("face", "girl");


ReactDOM.render(
    <Provider store={store}>
        <Router>
            <Layout/>
        </Router>
    </Provider>,
    document.getElementById("root")
);
